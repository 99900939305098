import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { BaseLayout } from "@/components/Layout/BaseLayout";
import { Header } from "@/components/Header/Header";
import { Button, ButtonType } from "@/components/Button/Button";
import { ButtonArc } from "@/components/ButtonArc/ButtonArc";
import Checkbox from "@/components/Checkbox/Checkbox";
import Typography from "@/components/Typography/Typography";
import { useAppSelector } from "@/hooks";
import { shallowEqual } from "react-redux";
import { useTheme } from "@/context/theme";
import { monitor } from "@/utils/monitoring.service";
import { messageService } from "@/utils/messaging.service";
import { useLanguage } from "@/context/language";

import "./Welcome.scss";

interface iWelcomeRequiredCheck {
  TNC: boolean;
  DataUsage: boolean;
}

export const WelcomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [onboarding, setOnboarding] = useState<string | null>(null);
  const [setting] = useAppSelector((state) => [state.setting], shallowEqual);
  const theme = useTheme();

  const [checks, setChecks] = useState<iWelcomeRequiredCheck>({
    TNC: false,
    DataUsage: false,
  });

  const __getUrl = (url_name: "website" | "privacy_policy" | "product_guidelines" | "terms_of_service"): string => {
    const settingsUrls = setting.pluginSettings?.urls ?? {};
    const lang = language ?? "en";

    if (lang in settingsUrls && url_name in settingsUrls[lang] && settingsUrls[lang][url_name] !== null) {
      return settingsUrls[lang][url_name] as string;
    } else if (lang !== "en" && "en" in settingsUrls && url_name in settingsUrls["en"] && settingsUrls["en"][url_name] !== null) {
      return settingsUrls["en"][url_name] as string;
    }
    return t("url_" + url_name);
  };

  const __canContinue = () => {
    return (
      (setting.pluginSettings?.hide_check_data_usage || checks.DataUsage) && (setting.pluginSettings?.hide_check_privacy || checks.TNC)
    );
  };

  const __performMeasurement = () => {
    if (!!__canContinue()) {
      messageService.startNewMeasurement(onboarding == null);
      navigate("/measurement/do", { state: { from: location.pathname } });
      if (!onboarding) {
        navigate("/measurement/onboarding", { state: { from: location.pathname } });
      }
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("onboarding");
    setOnboarding(data);
  }, []);

  return (
    <BaseLayout>
      <Header />
      <div className="flex flex-col grow items-center justify-center lg:flex-row">
        <div className="flex flex-col lg:flex-[100%] lg:px-24 md:mb-8">
          <span className="block lg:hidden font-bold text-center text-support-500 my-4 text-2xl text-[21px]">
            {t("welcome_title_web", "Get ready for your health check!")}
          </span>

          <img
            src={require("@/images/welcome-1.png")}
            className="h-[220px] w-auto sm:h-[360px] md:h-[420px] lg:w-[unset] object-center object-contain hide-short"
            alt=""
          />
        </div>

        <div className="flex flex-[100%] flex-col justify-center">
          <span className="hidden lg:block font-bold text-center text-primary text-support-500 mb-12 text-2xl lg:text-left lg:mb-8">
            {t("welcome_title_web", "Get ready for your health check!")}
          </span>

          <span className="font-bold text-support-500 uppercase tracking-widest mb-7 text-center sm:text-left text-[17px] sm:text-[14px]">
            {t("welcome_sub_title", "Follow these three easy steps:")}
          </span>

          <div>
            <div className="flex flex-row mb-4">
              <div className="mr-5">
                <ButtonArc
                  progress={40}
                  content={<span className="text-support-500">1</span>}
                  progressColor={theme.value.functional_tertiary_500}
                  track={theme.value.dark_mode}
                  trackColor={theme.value.support_100}
                />
              </div>
              <span className="text-support-500 text-[14px] md:text-[16px] my-auto">
                <Trans
                  i18nKey="welcome_point_1" // optional -> fallbacks to defaults if not provided
                  components={{ bold: <strong /> }}
                />
              </span>
            </div>

            <div className="flex flex-row mb-4">
              <div className="mr-5">
                <ButtonArc
                  progress={60}
                  content={<span className="text-support-500">2</span>}
                  progressColor={theme.value.functional_tertiary_500}
                  track={theme.value.dark_mode}
                  trackColor={theme.value.support_100}
                />
              </div>
              <span className="text-primary text-[14px] text-support-500 md:text-[16px] my-auto">
                <Trans
                  i18nKey="welcome_point_2" // optional -> fallbacks to defaults if not provided
                  components={{ bold: <strong /> }}
                />
              </span>
            </div>

            <div className="flex flex-row mb-4">
              <div className="mr-5">
                <ButtonArc
                  progress={100}
                  content={<span className="text-primary text-support-500">3</span>}
                  progressColor={theme.value.functional_tertiary_500}
                  track={theme.value.dark_mode}
                  trackColor={theme.value.support_100}
                />
              </div>
              <span className="text-primary text-support-500 md:text-[16px] text-[14px] my-auto">
                <Trans
                  i18nKey="welcome_point_3" // optional -> fallbacks to defaults if not provided
                  components={{ bold: <strong /> }}
                />
              </span>
            </div>
          </div>

          <div className="flex flex-row mt-4">
            {!setting.pluginSettings?.hide_check_privacy && (
              <Checkbox
                name="read_and_agree"
                value={checks.TNC}
                onChange={() => setChecks((v) => ({ ...v, TNC: !v.TNC }))}
                label={
                  <Typography.Text
                    size={4}
                    className="text-primary text-support-500 ml-3 cursor-pointer select-none md:text-[16px] text-[14px]"
                  >
                    {t("read_and_agree")}
                    <a className="underline cursor-pointer" href={__getUrl("terms_of_service")} target="blank">
                      {t("terms_of_service")}
                    </a>
                    {t("and")}
                    <a className="underline cursor-pointer" href={__getUrl("privacy_policy")} target="blank">
                      {t("privacy_policy")}
                    </a>
                  </Typography.Text>
                }
              />
            )}
          </div>

          <div className="flex flex-row mt-4 mb-10">
            {!setting.pluginSettings?.hide_check_data_usage && (
              <Checkbox
                name="data_usage"
                value={checks.DataUsage}
                onChange={() => setChecks((v) => ({ ...v, DataUsage: !v.DataUsage }))}
                label={
                  <Typography.Text
                    size={4}
                    className="text-primary text-support-500 ml-3 cursor-pointer select-none md:text-[16px] text-[14px]"
                  >
                    {t("agree_data_usage")}
                  </Typography.Text>
                }
              />
            )}
          </div>

          <div className="flex flex-col lg:flex-row gap-[16px] lg:gap-[24px] mb-[30px] md:max-w-[250px] md:mx-auto lg:mx-[unset] lg:max-w-[unset] lg:mb-[unset]">
            <Button
              text={t("perform_measurement")}
              className="uppercase px-[32px] self-center shadow-xl"
              type={ButtonType.Accent}
              onClick={__performMeasurement}
              disabled={!__canContinue()}
            />
            <Button
              text={t("read_more")}
              className="uppercase px-[32px] self-center"
              onClick={() => {
                monitor.trackClickEvent("ReadMore");
                window.open(__getUrl("website"), "_blank", "noopener,noreferrer");
              }}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
