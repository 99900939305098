import ReactDOM from "react-dom/client";
import Modal from "react-modal";
import _refiner from "refiner-js";

import "@/styles/index.css";
import "react-loading-skeleton/dist/skeleton.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { monitor } from "./utils/monitoring.service";

Modal.setAppElement("#root");

const appEnv: string = process.env.REACT_APP_ENV ?? "localhost";
const disableLogging = !appEnv || appEnv === "localhost";

if (!disableLogging) {
  const loggingEnv: string = process.env.REACT_APP_DATADOG_ENV ?? "localhost";
  monitor.setup(loggingEnv);
}

if (process.env.REACT_APP_REFINER_PROJECT_ID) {
  _refiner("setProject", process.env.REACT_APP_REFINER_PROJECT_ID);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
