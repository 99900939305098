import { useTheme } from "@/context/theme";
import { SVGProps } from "react";

export const IconInAppBrowser = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg width="74" height="71" viewBox="0 0 74 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.554 11.2981C70.3548 12.0416 69.4253 12.2907 68.881 11.7464L67.5699 10.4353C67.1794 10.0448 66.5462 10.0448 66.1557 10.4353L33.4289 43.1621C32.6478 43.9431 31.3815 43.9431 30.6004 43.1621C29.8194 42.381 29.8194 41.1147 30.6004 40.3336L63.3272 7.60685C63.7178 7.21633 63.7178 6.58316 63.3272 6.19264L61.5325 4.39789C60.9882 3.85356 61.2372 2.9241 61.9808 2.72486L72.019 0.035136C72.7626 -0.164103 73.443 0.516311 73.2437 1.25988L70.554 11.2981ZM31.7217 6.92999H33.7217C34.8262 6.92999 35.7217 7.82542 35.7217 8.92999C35.7217 10.0346 34.8262 10.93 33.7217 10.93H31.7217H8.72168C6.51254 10.93 4.72168 12.7209 4.72168 14.93V62.93C4.72168 65.1391 6.51254 66.93 8.72168 66.93H56.7217C58.9308 66.93 60.7217 65.1391 60.7217 62.93V36.93V34.93C60.7217 33.8254 61.6171 32.93 62.7217 32.93C63.8262 32.93 64.7217 33.8254 64.7217 34.93V36.93V62.93C64.7217 67.3483 61.14 70.93 56.7217 70.93H8.72168C4.3034 70.93 0.72168 67.3483 0.72168 62.93V14.93C0.72168 10.5117 4.3034 6.92999 8.72168 6.92999H31.7217Z"
        fill={theme.value.support_500}
      />
    </svg>
  );
};
