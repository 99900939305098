import {
  BiomarkersResponse,
  BucketFeedbackResponse,
  BucketResult,
  BucketSummary,
  IntelliProveAPIError,
  IntelliProveSDKError,
  UnprocessableVideoResponse,
} from "intelliprove-streaming-sdk";
import { useCallback, useContext, useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "@/hooks";
import { resetMeasurement, setBucket, setFeedback } from "@/store/reducers/measurement.reducer";
import { iAppModal } from "@/components/Modal/Modal";
import { useLanguage } from "@/context/language";
import { messageService } from "@/utils/messaging.service";
import { useNavigate, useLocation } from "react-router-dom";
import { debugLog, mapErrorCode } from "@/utils/helper";
import { monitor } from "@/utils/monitoring.service";
import { useSDK } from "@/providers/Sdk.provider";

export const useResult = (uuid?: string, response?: BiomarkersResponse | UnprocessableVideoResponse) => {
  let slowTimeout: NodeJS.Timer;

  const sdkRef = useSDK();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  const [measurement, setting] = useAppSelector((state) => [state.measurement, state.setting], shallowEqual);
  const [activeBucket, setActiveBucket] = useState<BucketResult | undefined>();
  const [activeFeedback, setActiveFeedback] = useState<BucketSummary | null | undefined>();
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [resultError, setResultError] = useState<iAppModal | undefined>();

  const selectBucket = useCallback(
    (bucket: BucketResult) => {
      const feedbackSummary = measurement.feedback?.summaries.find((d) => d.name === bucket.name);
      if (feedbackSummary) {
        setActiveFeedback(new BucketSummary(feedbackSummary));
      } else {
        setActiveFeedback(null);
      }

      setActiveBucket(new BucketResult(bucket));
    },
    [measurement],
  );

  const __fetchResult = async () => {
    if (!uuid) {
      console.error(`Results page: UUID is required!`);
      return;
    }

    if (!sdkRef) {
      throw new ReferenceError("SDK Ref is not set or SDK not initialized yet!");
    }

    slowTimeout = setTimeout(() => {
      setAlert(
        t("motivation_3", "We're almost there!"), // Calculating your results is taking a bit longer than expected…
      );
    }, 10000);

    let biomarkersResult = response;
    debugLog(`Results from measurement page => `, biomarkersResult);

    // Fetch results when no data is passed from measurement page
    if (!biomarkersResult) {
      biomarkersResult = await sdkRef.instance!.getResults(uuid);
      messageService.results(biomarkersResult);
    }

    if (biomarkersResult instanceof UnprocessableVideoResponse) {
      debugLog(`State was not BiomarkersResponse, showing error`, biomarkersResult);
      const errorKeys = mapErrorCode(biomarkersResult.errorCode);
      showErrorModal(
        t(errorKeys.title),
        t(errorKeys.text),
        `Error on final results [${biomarkersResult.errorType} - Code: ${biomarkersResult.errorCode}]`,
      );
      return;
    }

    setAlert(undefined);
    clearInterval(slowTimeout);

    const buckets = await sdkRef.instance!.getBuckets(uuid);
    messageService.buckets(buckets.buckets);
    dispatch(setBucket(buckets));

    if (buckets.buckets.length !== 0) {
      setActiveBucket(buckets.buckets[0]);
    }
  };

  const showErrorModal = (title_key: string, desc_key: string, reason?: string) => {
    setAlert(undefined);
    clearInterval(slowTimeout);
    monitor.trackErrorModal(reason);
    setResultError({
      isOpen: true,
      title: t(title_key),
      text: t(desc_key),
      action: {
        text: t("try_again"),
        onPress: () => {
          navigate("/measurement/do", { state: { from: location.pathname } });
          monitor.trackTryAgain();
        },
      },
      secondaryAction: {
        text: t("continue_without_health_scan"),
        onPress:
          setting.pluginSettings?.embedded ?? true
            ? () => {
                messageService.userFinished();
                messageService.dismiss();
                monitor.trackContinue();
              }
            : () => {},
      },
    });
  };

  const handleIntelliProveSdkError = (e: any) => {
    var title = t("error_oops");
    var text = t("error_oops_description");

    console.error(`SDK Error: ${e}`);
    monitor.trackError(e);

    if (e instanceof IntelliProveSDKError) {
      // let sdk_error = e as IntelliProveSDKError
      // Add switch here to catch specific SDK errors
    }

    if (e instanceof IntelliProveAPIError) {
      // let api_error = e as IntelliProveAPIError
      // Add switch here to catch specific API errors
    }

    showErrorModal(title, text, `SDK Error was raised [${e}]`);
  };

  useEffect(() => {
    (async () => {
      if (!setting || (setting && !setting.actionToken)) {
        console.warn(`App hasn't been configured correctly yet!`);
        return;
      }

      try {
        await __fetchResult();
      } catch (e) {
        messageService.error("Error while fetching results or buckets", -1, true);
        handleIntelliProveSdkError(e);
      }
    })();

    return () => {
      dispatch(resetMeasurement());
    };
  }, [setting, uuid]);

  useEffect(() => {
    if (uuid === undefined) {
      return;
    }

    (async () => {
      if (!sdkRef) {
        throw new ReferenceError("SDK Ref is not set or SDK not initialized yet!");
      }

      var feedback: BucketFeedbackResponse | undefined = undefined;

      try {
        feedback = await sdkRef.instance!.getBucketFeedback(uuid, language, true);
      } catch (e: any) {
        if (e instanceof IntelliProveAPIError && e.status_code === 404) {
          setActiveFeedback(null);
          return;
        }

        monitor.trackError(e);
        messageService.error("Failed to fetch bucket summaries", -1, false);
        // Don't handle error with modal, but handle quitely
        setActiveFeedback(null);
      }

      if (feedback) {
        messageService.bucketSummaries(feedback.summaries);
        dispatch(setFeedback(feedback));

        if (feedback.summaries.length !== 0) {
          setActiveFeedback(feedback.summaries[0]);
        }
      } else {
        setActiveFeedback(null);
      }
    })();
  }, [language]);

  return {
    feedback: measurement.feedback,
    buckets: measurement.buckets,
    activeBucket,
    selectBucket,
    activeFeedback,
    alert,
    resultError,
  };
};
